/* import lato font */
@import url('https://fonts.googleapis.com/css?family=Lato:300, 400,700&display=swap');

:root {
    --blue: #1a73e8; /* Replace with your desired color */
    --green: #188038;
    --red: #d93025;
    --orange: #d99125;
    --darkGrey: #202124;
    --midGrey: #5a5c62;
    --lightGrey: #999ca3;
}
.stripedTable thead th {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}
.stripedTable tbody tr:nth-child(odd) {
    background: #f8f9fa;
}

.stripedTable tr td {
    padding-top: 6px!important;
    padding-bottom: 6px!important;
}

a.active {
    color: #1a73e8!important;
    font-weight: 700;
}

.content {
    margin-left: 250px;
}

.pieChartLegend {
    font-size: 30px;
    vertical-align: bottom;
    width: 100%;
    height: 25px;
    
}

h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    color: var(--blue)
}
h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
}
h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
}

p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--darkGrey);
}

p.branchTag {
    font-size: 12px;
}


