

@media print {

    /* hide sidebar */
    .sidebar {
        display: none;
    }
    /* hide filter */
    .filterForm {
        display: none;
    }
    /* enlarge content area */
    .content {
        margin-left: 0;
    }

    /* table */
    .stripedTable thead th {
        padding-left: 5px!important;
        padding-right: 5px!important;

    }

    .stripedTable tbody td {
        padding-left: 5px!important;
        padding-right: 5px!important;
    }
   

    .reportContainer {
        page-break-inside: avoid;
    }

    .forcePageBreak {
        page-break-before: always;
    }

    @page {
        size: A6;
        orientation: portrait;
        
        margin-top: 15mm;
        margin-bottom: 25mm;
        margin-left: 10mm;
        margin-right: 10mm;
    
        @top-center {
            content: "Averly Queue Management Report";
        }

        @bottom-right {
            counter-increment: page;
            counter-reset: page 1;
            content: "page " counter(page);
        }
        
    }
    

}